/* eslint-disable @next/next/no-img-element */
/** @format */

import Link from 'next/link';
import Collapse from 'react-bootstrap/Collapse';
import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useRouter} from 'next/router';
import Image from 'next/image';
import Cookies from 'js-cookie';

import CloseImg from '@imageWrapper/closeImg';
import {useApp} from '@context/AppProvider';
import {useCart} from '@context/CartProvider';
import Loading from '@components/Loading';
import ConfirmationModal from '@models/confirmation.modal';

import ParentMenu from './ParentMenu.component';

const wrapperRef = React.createRef();

function MobileNavComponent(props) {
	const router = useRouter();
	const {
		logout,
		idb: {user, origin, currencyData, isoCode},
		fetchSession,
		showConvertCurrency,
	} = useApp();
	const {updateCartCount, updateWishlistCount} = useCart();
	const {styles, categories, showModel, showHideMobileMenu} = props;
	const [state, setState] = useState({
		open: false,
		openShop: false,
		currency: false,
		selectedCat: null,
		loader: false,
		loading: false,
	});

	const locationRef = useRef(null);

	const outSideModalClickEvent = useCallback(() => {
		if (wrapperRef && !wrapperRef.current?.contains(event.target)) {
			setState(preState => ({
				...preState,
				open: false,
			}));
			document.removeEventListener('mousedown', outSideModalClickEvent);
		}
	}, []);

	useEffect(() => {
		if (state.open)
			document.addEventListener('mousedown', outSideModalClickEvent);
	}, [state.open, outSideModalClickEvent]);

	useEffect(() => {
		showHideMobileMenu(false);
	}, [router, showHideMobileMenu]);
	useEffect(() => {
		setState(preState => ({
			...preState,
			loader: false,
		}));
	}, [origin]);

	const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
	const onLogout = () => {
		updateCartCount(0);
		updateWishlistCount(0);
		logout();
		setShowLogoutConfirm(true);
		showHideMobileMenu(false);
		let timeout = setTimeout(() => {
			setShowLogoutConfirm(false);
			clearTimeout(timeout);
		}, 5000);
	};
	const signOutEventButton = () => {
		setShowLogoutConfirm(false);
	};
	const switchCurrency = useCallback(
		data => {
			Cookies.set('cur', data.currency.toLowerCase());
			Cookies.set('isoCode', data.isoCode);
			fetchSession(true, data.isoCode);
			setState(preState => ({
				...preState,
				open: false,
				loading: true,
			}));
		},
		[fetchSession],
	);
	return (
		<div className={styles.mobileMenuBlock} ref={locationRef}>
			<div className={styles.closeBlock}>
				<CloseImg click={() => showHideMobileMenu(false)} />
			</div>
			<div className={styles.logoPosition}>
				<div className={`${styles.logo} ${styles.menuLogo}`}>
					<Link href='/'>
						<a>
							<img
								className={styles.logoImage}
								src='/img/main-logo.png'
								alt='logo'
							/>
							{/* <Image alt='' src='/img/main-logo.png' width='160' height='45' /> */}
						</a>
					</Link>
				</div>
			</div>
			<div className={styles.mobileNavList}>
				<ul>
					{categories.map(item => {
						return (
							(state.selectedCat === null ||
								state.selectedCat._id === item._id) && (
								<li key={item._id}>
									<ParentMenu
										category={item}
										setSelectedCat={cat => {
											setState(preState => ({
												...preState,
												selectedCat: cat,
											}));
										}}
										selectedCat={state.selectedCat}
										styles={styles}
									/>
								</li>
							)
						);
					})}
					<li>
						<Link href='/all-stores'>Stores</Link>
					</li>
					{/* {state.selectedCat === null && (
						<li>
							<Link href={`/brands/women`}>Brands</Link>
						</li>
					)} */}
					<li>
						<Link href={`/drop-auctions`}>Drop Auctions</Link>
					</li>
					<li>
						<Link href={`/good-causes`}>
							<a className={styles.goodCauseLink}>
								<div className={styles.goodCauseLogoHover}>
									<Image
										alt='Better That'
										src='/img/heart-black.png'
										height='20'
										width='20'
									/>
								</div>
								<div className={styles.goodCauseLogo}>
									<Image
										alt='Better That'
										src='/img/heart.png'
										height='20'
										width='20'
									/>
								</div>
								Good Causes
							</a>
						</Link>
					</li>

					{user && user._id && (
						<>
							<li>
								<Link href={`/my-account`}>My Account</Link>
							</li>
							<li>
								<span onClick={() => onLogout()}>Logout</span>
							</li>
						</>
					)}
				</ul>
			</div>
			<div className={styles.mobileBottom}>
				{!user && (
					<>
						<div className={styles.signBtnMob}>
							<span onClick={() => showModel('login')}>Sign In</span>
						</div>
						<div className={`${styles.signBtnMob} register-btn`}>
							<span onClick={() => showModel('register')}>Register</span>
						</div>
					</>
				)}
				{showConvertCurrency() && (
					<div className={styles.countryList}>
						<p>Select your Country</p>
						<span
							className={styles.styleOutside}
							data-toggle='collapse'
							onClick={() => {
								locationRef.current.scroll({behavior: 'smooth'});
								setState(preState => ({
									...preState,
									open: !state.open,
								}));
							}}
							aria-expanded={state.open}
						>
							{state.loader ? (
								<div className={styles.locationLodingPos}>
									<Loading.LoaderThree />
								</div>
							) : (
								<>
									{currencyData.find(
										mp => mp.isoCode.toLowerCase() === isoCode.toLowerCase(),
									) && (
										<Image
											layout='intrinsic'
											width={30}
											height={15}
											alt='ausimg'
											style={{top: '3px'}}
											src={
												currencyData.find(
													mp =>
														mp.isoCode.toLowerCase() === isoCode.toLowerCase(),
												)?.src
											}
										/>
									)}
									{` ${
										currencyData.find(
											mp => mp.isoCode.toLowerCase() === isoCode.toLowerCase(),
										)?.name
									}`}
								</>
							)}
						</span>
						<Collapse in={state.open}>
							<ul
								className={`collapse country-sub`}
								id='country-one'
								ref={wrapperRef}
							>
								{currencyData.map((cr, i) => (
									<li key={i} role='button' onClick={() => switchCurrency(cr)}>
										<div className={styles.gridContainer}>
											<span>
												<Image alt='' src={cr.src} width='30' height='15' />
											</span>
											{/* <span> */}
											<p>{cr.name}</p>
											{/* </span> */}
										</div>
									</li>
								))}
							</ul>
						</Collapse>
					</div>
				)}
			</div>
			{showLogoutConfirm && (
				<ConfirmationModal
					eventButton={signOutEventButton}
					headingMessage={''}
					message={'You have signed out successfully.'}
					buttonShow={false}
					action={'none'}
					modalType={'success'} //"error","warning","success"
				></ConfirmationModal>
			)}
			{state.loading && <Loading />}
		</div>
	);
}

export default MobileNavComponent;

/** @format */

import Link from 'next/link';

function SubMenuComponent(props) {
	const {styles, master, categories, changeActiveMenu} = props;
	return (
		<div className={styles.subMenu}>
			<div className={styles.subMenuInner}>
				<div className='container'>
					<div className={styles.navScrollBlock}>
						<div className={styles.leftMenuList}>
							<ul>
								<li onClick={() => changeActiveMenu('')}>
									<Link href={`/products/${master.slug}`}>View all</Link>
								</li>
								<li onClick={() => changeActiveMenu('')}>
									<Link href={`/products/${master.slug}?sortBy=1`}>New In</Link>
								</li>
								<li onClick={() => changeActiveMenu('')}>
									<Link href={`/brands/${master.slug}`}>Brands</Link>
								</li>
							</ul>
						</div>
						<div className={styles.rightMenuList}>
							<div className='row'>
								{categories.map(item => {
									return (
										<div className='col-md-3' key={item._id}>
											<div className={styles.navOuterBlock}>
												<Link href={`/products/${item.slug}`} passHref={true}>
													<a
														className={styles.navHd}
														onClick={() => changeActiveMenu('')}
													>
														{' '}
														{item.Name}
													</a>
												</Link>
												<ul>
													{item.children.map(c => {
														return (
															<li
																key={c._id}
																onClick={() => changeActiveMenu('')}
															>
																<Link href={`/products/${c.slug}`}>
																	{c.Name}
																</Link>
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SubMenuComponent;

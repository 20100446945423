/** @format */

import Collapse from 'react-bootstrap/Collapse';
import {useState} from 'react';
import Link from 'next/link';

function SubMenu(props) {
	const {styles, category} = props;
	const [open, setOpen] = useState(false);
	return (
		<>
			<span
				data-toggle='collapse'
				onClick={() => setOpen(!open)}
				aria-expanded={open}
			>
				{category.Name}
			</span>
			<Collapse in={open}>
				<ul className={`collapse ${styles.mobileSubMenu}`} id='sub-menu-two'>
					{category.children.map(c => {
						return (
							<li key={c._id}>
								<Link href={`/products/${c.slug}`}>{c.Name}</Link>
							</li>
						);
					})}
				</ul>
			</Collapse>
		</>
	);
}

export default SubMenu;

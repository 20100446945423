/** @format */

import Collapse from 'react-bootstrap/Collapse';
import {useState} from 'react';
import Link from 'next/link';

import SubMenu from './SubMenu.component';
function ParentMenu(props) {
	const {styles, category, setSelectedCat, selectedCat} = props;
	const [open, setOpen] = useState(false);
	return (
		<>
			{selectedCat && (
				<ul>
					<li>
						<span
							data-toggle='collapse'
							onClick={() => {
								setSelectedCat(null);
								setOpen(false);
							}}
							aria-expanded='false'
						>
							<i className='fas fa-chevron-left'></i> Back
						</span>
					</li>
				</ul>
			)}

			<span
				data-toggle={styles.collapse}
				onClick={() => {
					setSelectedCat(!open ? category : null);
					setOpen(!open);
				}}
				aria-expanded={open}
			>
				{category.Name}
			</span>

			<Collapse in={open}>
				<ul className={`collapse ${styles.mobileSubMenu}`} id='sub-menu-one'>
					<li className={styles.smallText}>
						<Link href={`/products/${category.slug}`}>VIEW ALL</Link>
					</li>
					<li className={styles.smallText}>
						<Link href={`/products/${category.slug}?sortBy=1`}>NEW IN</Link>
					</li>
					<li className={styles.smallText}>
						<Link href={`/brands/${category.slug}`}>BRANDS</Link>
					</li>
					{category.children.map(p => {
						return (
							<li key={p._id}>
								<SubMenu styles={styles} category={p} />
							</li>
						);
					})}
				</ul>
			</Collapse>
		</>
	);
}

export default ParentMenu;

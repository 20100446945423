/** @format */

import {useCategory} from '@context/CategoryProvider';

import DesktopNavComponent from './desktop/DesktopNav.component';
import MobileNavComponent from './mobile/MobileNav.component';
function NavBarComponent(props) {
	const {styles, showModel, showHideMobileMenu} = props;
	const {categories} = useCategory();
	return (
		<>
			{/* Desktop nav bar start */}
			{!props.hideMenu && (
				<DesktopNavComponent styles={styles} categories={categories} />
			)}
			{/* Desktop nav bar end */}

			{/* Mobile nav bar start */}
			<MobileNavComponent
				showModel={showModel}
				styles={styles}
				categories={categories}
				showHideMobileMenu={showHideMobileMenu}
			/>
			{/* Mobile nav bar end */}
		</>
	);
}

export default NavBarComponent;

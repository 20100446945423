/** @format */
import React, {useState, useEffect} from 'react';
import Link from 'next/link';
import Image from 'next/image';

import SubMenuComponent from './SubMenu.component';
const wrapperRef = React.createRef();
function DesktopNavComponent(props) {
	const {styles} = props;
	const [activeMenu, setActiveMenu] = useState('');

	useEffect(() => {
		const outSideModalClickEvent = () => {
			if (wrapperRef && !wrapperRef.current?.contains(event.target)) {
				setActiveMenu('');
			}
		};

		const removeListener = () => {
			document.removeEventListener('mousedown', outSideModalClickEvent);
		};
		document.addEventListener('mousedown', outSideModalClickEvent);
		return () => {
			removeListener();
		};
	}, []);

	const changeActiveMenu = _id => {
		if (activeMenu === _id) setActiveMenu('');
		else setActiveMenu(_id);
	};
	const categories = props.categories.map(item => {
		return (
			<li className='dropdown-open links' key={item._id}>
				<span
					className={`${activeMenu === item._id ? styles.link_active : ''}`}
					onClick={() => changeActiveMenu(item._id)}
				>
					{item.Name}{' '}
				</span>
				{activeMenu === item._id && (
					<SubMenuComponent
						styles={styles}
						master={item}
						categories={item.children}
						changeActiveMenu={changeActiveMenu}
					></SubMenuComponent>
				)}
			</li>
		);
	});
	return (
		<div className={styles.menuOuter} ref={wrapperRef}>
			<div className={styles.navigation}>
				<ul className='d-flex'>
					{categories}
					{/* <li onClick={() => changeActiveMenu('')}>
						<Link href={`/brands/women`}>Brands</Link>
					</li> */}
					<li className='dropdown-open links'>
						<Link href='/all-stores'>
							<a onClick={() => changeActiveMenu('')}>Stores</a>
						</Link>
					</li>
					<li onClick={() => changeActiveMenu('')}>
						<Link href={`/drop-auctions`}>Drop Auctions</Link>
					</li>
					<li onClick={() => changeActiveMenu('')}>
						<Link href={`/good-causes`}>
							<a
								style={{
									paddingLeft: '15px ',
									position: 'relative',
								}}
								className={styles.goodCauseLink}
							>
								<div className={styles.goodCauseLogoHover}>
									<Image
										alt='Better That'
										src='/img/heart-black.png'
										height='20'
										width='20'
									/>
								</div>
								<div className={styles.goodCauseLogo}>
									<Image
										alt='Better That'
										src='/assets/images/heart.svg'
										height='20'
										width='20'
									/>
								</div>
								Good Causes
							</a>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default DesktopNavComponent;
